var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("Page", [
    _c(
      "div",
      { staticClass: "home-page" },
      [
        _c("zui-header"),
        _c("div", { staticClass: "content" }, [
          _c("div", { staticClass: "logo-box" }, [
            _c("img", {
              attrs: { src: "https://www.achencybe.com/img/pc/logo.png" },
            }),
          ]),
          _c(
            "div",
            { staticClass: "code-box" },
            [
              _vm.type === "AC"
                ? [
                    _c("img", {
                      staticStyle: {
                        display: "block",
                        margin: "-100px auto 0",
                      },
                      attrs: {
                        src: "https://www.achencybe.com/img/pc/footerDetail/1.png",
                        alt: "",
                      },
                    }),
                    _c("div", [_vm._v("微信扫一扫添加你的专属Specialist专家")]),
                  ]
                : _vm._e(),
              _vm.type === "douyin"
                ? [
                    _c("img", {
                      staticStyle: {
                        display: "block",
                        margin: "-100px auto 0",
                        width: "300px",
                      },
                      attrs: {
                        src: "https://www.achencybe.com/img/pc/footerDetail/2_1.png",
                        alt: "",
                      },
                    }),
                    _c(
                      "div",
                      {
                        staticStyle: {
                          "margin-top": "80px",
                          "font-size": "30px",
                        },
                      },
                      [_vm._v("打开抖音扫一扫")]
                    ),
                  ]
                : _vm._e(),
              _vm.type === "ebay"
                ? [
                    _c(
                      "div",
                      {
                        staticStyle: {
                          "margin-top": "-100px",
                          "font-size": "30px",
                        },
                      },
                      [
                        _vm._v("eBay搜索“"),
                        _c("span", { staticStyle: { "font-weight": "500" } }, [
                          _vm._v("AchenCybe"),
                        ]),
                        _vm._v("”"),
                      ]
                    ),
                    _c("div", { staticStyle: { "margin-top": "20px" } }, [
                      _vm._v("关注以获取更多实时资讯"),
                    ]),
                  ]
                : _vm._e(),
              _vm.type === "taobao"
                ? [
                    _c("div", { staticStyle: { "font-size": "30px" } }, [
                      _vm._v("AchenCybe Club"),
                    ]),
                    _c("div", { staticStyle: { "font-size": "18px" } }, [
                      _vm._v("淘宝网企业店铺"),
                    ]),
                    _c("img", {
                      staticStyle: {
                        display: "block",
                        margin: "50px auto 30px",
                        width: "280px",
                      },
                      attrs: {
                        src: "https://www.achencybe.com/img/pc/footerDetail/3.png",
                        alt: "",
                      },
                    }),
                    _c(
                      "div",
                      {
                        staticStyle: { "font-size": "18px", color: "#a5a5a5" },
                      },
                      [_vm._v("手机淘宝扫一扫")]
                    ),
                  ]
                : _vm._e(),
              _vm.type === "gzh"
                ? [
                    _c("img", {
                      staticStyle: {
                        display: "block",
                        margin: "-100px auto 0",
                        width: "256px",
                      },
                      attrs: {
                        src: "https://www.achencybe.com/img/pc/footerDetail/5.jpg",
                        alt: "",
                      },
                    }),
                    _c(
                      "div",
                      {
                        staticStyle: {
                          "margin-top": "20px",
                          "font-size": "30px",
                        },
                      },
                      [
                        _vm._v("公众号搜索“"),
                        _c("span", { staticStyle: { "font-weight": "500" } }, [
                          _vm._v("雷肯茨博"),
                        ]),
                        _vm._v("”"),
                      ]
                    ),
                    _c("div", { staticStyle: { "margin-top": "10px" } }, [
                      _vm._v("关注以获取更多实时资讯"),
                    ]),
                  ]
                : _vm._e(),
              _vm.type === "xhs"
                ? [
                    _c("img", {
                      staticStyle: { display: "block", margin: "0px auto 0" },
                      attrs: {
                        src: "https://www.achencybe.com/img/pc/footerDetail/4.png",
                        alt: "",
                      },
                    }),
                  ]
                : _vm._e(),
            ],
            2
          ),
        ]),
        _c("zui-footer"),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }