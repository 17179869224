<template>
  <Page>
    <div class="home-page">
      <zui-header />
      <div class="content">
        <div class="logo-box">
          <img src="https://www.achencybe.com/img/pc/logo.png">
        </div>
        <div class="code-box">
          <template v-if="type==='AC'">
            <img src="https://www.achencybe.com/img/pc/footerDetail/1.png" alt="" style="display: block;margin:-100px auto 0;">
            <div>微信扫一扫添加你的专属Specialist专家</div>
          </template>
<!--          <template v-if="type === 'douyin'">-->
<!--            <img src="https://www.achencybe.com/img/pc/footerDetail/2.png" alt="" style="display: block;margin:-100px auto 0;width:256px">-->
<!--            <div style="margin-top:80px;font-size:30px;">CARBEN app搜索“<span style="font-weight:500">AchenCybe</span>”</div>-->
<!--            <div style="margin-top:20px;">关注以获取更多实时资讯</div>-->
<!--          </template>-->
          <template v-if="type === 'douyin'">
            <img src="https://www.achencybe.com/img/pc/footerDetail/2_1.png" alt="" style="display: block;margin:-100px auto 0;width:300px">
            <div style="margin-top:80px;font-size:30px;">打开抖音扫一扫</div>
          </template>
          <template v-if="type === 'ebay'">
            <div style="margin-top:-100px;font-size:30px;">eBay搜索“<span style="font-weight:500">AchenCybe</span>”</div>
            <div style="margin-top:20px;">关注以获取更多实时资讯</div>
          </template>
          <template v-if="type === 'taobao'">
            <div style="font-size:30px;">AchenCybe Club</div>
            <div style="font-size:18px;">淘宝网企业店铺</div>
            <img src="https://www.achencybe.com/img/pc/footerDetail/3.png" alt="" style="display: block;margin:50px auto 30px;width:280px;">
            <div style="font-size:18px;color:#a5a5a5">手机淘宝扫一扫</div>
          </template>
          <template v-if="type === 'gzh'">
            <img src="https://www.achencybe.com/img/pc/footerDetail/5.jpg" alt="" style="display: block;margin:-100px auto 0;width:256px">
            <div style="margin-top:20px;font-size:30px;">公众号搜索“<span style="font-weight:500">雷肯茨博</span>”</div>
            <div style="margin-top:10px;">关注以获取更多实时资讯</div>
          </template>
          <template v-if="type==='xhs'">
            <img src="https://www.achencybe.com/img/pc/footerDetail/4.png" alt="" style="display: block;margin:0px auto 0;">
          </template>
        </div>
      </div>
      <zui-footer />
    </div>
  </Page>
</template>

<script>

import Page from "../../../components/Page";
import ZuiHeader from "../../../components/Header";
import ZuiFooter from "../../../components/Footer";
export default {
  name: "footerDetail",
  components: { Page, ZuiHeader, ZuiFooter },
  computed:{
    type(){
      return this.$route.query.type
    }
  }
};
</script>

<style scoped lang="scss">
.content{
  width:54%;
  margin:200px auto 200px;
  box-shadow: #0c0c0c -5px 9px 17px 4px;
  .logo-box{
    width:100%;
    padding:100px 0;
    background-color:#121212;
    img{
      width:20%;
    }
  }
  .code-box{
    background-color: #fff;
    width:100%;
    padding:3% 0;
    height:700px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
</style>